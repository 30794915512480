// =========================================================
// * Vue Material Kit PRO - v1.3.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-kit-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import store from "@/services"; // vuex
import axios from 'axios'

// begin helper
import auth from "@/helper/auth";
// end helper

import router from "./router";

import MaterialKit from "./plugins/material-kit";

axios.defaults.withCredentials = true;

window.axios = axios
window.auth = auth // inisialisasi global untuk Helper/methods.js

Vue.config.productionTip = false;

Vue.use(MaterialKit);

new Vue({
  render: h => h(App),
  router,
  store // vuex
}).$mount("#app");
