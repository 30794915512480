<template>
  <div class="wrapper">
    <div
      id="main-panel"
      class="main"
    >
      <!-- Headers Section -->
      <div
        id="headers"
        class="cd-section"
      >
        <headers />
      </div>

      <!-- Patner Section -->
      <div
        id="patner"
        class="cd-section"
      >
        <patner />
      </div>

      <!-- Tentang Kami Section -->
      <div
        id="tentangkami"
        class="cd-section"
      >
        <tentang-kami />
      </div>

      <!-- Pengurus Section -->
      <div
        id="pengurusaktif"
        class="cd-section"
      >
        <pengurus-aktif />
      </div>

      <!-- Pendaftaran Anggota Section -->
      <div
        id="pendaftarananggota"
        class="cd-section"
      >
        <pendaftaran-anggota />
      </div>

    </div>
    <nav
      id="cd-vertical-nav"
      class="vertical-nav-active"
    >
      <ul>
        <li>
          <a
            href="javascript:void(0)"
            data-number="1"
            @click="scrollToElement('headers')"
          >
            <span class="cd-dot" />
            <span class="cd-label">Selamat Datang</span>
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0)"
            data-number="2"
            @click="scrollToElement('patner')"
          >
            <span class="cd-dot" />
            <span class="cd-label">Patner</span>
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0)"
            data-number="3"
            @click="scrollToElement('tentangkami')"
          >
            <span class="cd-dot" />
            <span class="cd-label">Tentang Kami</span>
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0)"
            data-number="4"
            @click="scrollToElement('pengurusaktif')"
          >
            <span class="cd-dot" />
            <span class="cd-label">Pengurus</span>
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0)"
            data-number="6"
            @click="scrollToElement('pendaftarananggota')"
          >
            <span class="cd-dot" />
            <span class="cd-label">Pendaftaran Anggota</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Headers from "./sections/Headers";
import Features from "./sections/Features";
import Blogs from "./sections/Blogs";
import Teams from "./sections/Teams";
import Projects from "./sections/Projects";
import Pricing from "./sections/Pricing";
import Testimonials from "./sections/Testimonials";
import ContactUs from "./sections/ContactUs";
import Mixins from "@/plugins/basicMixins";

// begin petisi section
import patner from "./petisiSections/patner";
import tentangKami from "./petisiSections/tentangKami";
import pengurusAktif from './petisiSections/pengurusAktif.vue';
import pendaftaranAnggota from './petisiSections/pendaftaranAnggota.vue';
// end petisi section

export default {
  components: {
    Headers,
    Features,
    Blogs,
    Teams,
    Projects,
    Pricing,
    Testimonials,
    ContactUs,

    // begin petisi section
    patner,
    tentangKami,
    pengurusAktif,
    pendaftaranAnggota
    // begin petisi section
  },
  mixins: [Mixins.VerticalNav]
};
</script>

<style lang="scss">
.vertical-nav-active {
  display: block;
}

@media all and (max-width: 768px) {
  .vertical-nav-active {
    display: none;
  }
}
</style>
