<template>
  <div class="wrapper">
    <h4 class="title title-margin">Stats</h4>
    <ul class="list-unstyled">
      <li><b>60</b> Portofolio</li>
      <li><b>4</b> Keahlian</li>
      <li><b>331</b> Pengaruh</li>
      <li><b>1.2K</b> Disukai</li>
    </ul>
    <hr />
    <div class="md-layout">
      <div class="md-layout-item md-size-50 mx-auto">
        <h4 class="title title-margin">Biodata</h4>
      </div>
      <div class="md-layout-item md-size-50 mx-auto">
        <md-button class="md-success md-just-icon md-round md-sm">
          <md-icon>edit</md-icon>
          <md-tooltip md-direction="top"> Edit Biodata </md-tooltip>
        </md-button>
      </div>
    </div>
    <p class="description">
      French luxury footwear and fashion. The footwear has incorporated shiny,
      red-lacquered soles that have become his signature.
    </p>
    <ul class="list-unstyled">
      <li>@syarif04</li>
      <li>Akhmad Syarif</li>
      <li>082738236286236</li>
      <li>syarif@gmail.com</li>
      <li>S1 (Teknik Informatika)</li>
      <li>Frontend di perusahaan Meta</li>
    </ul>

    <hr />
    <h4 class="title title-margin">Fokus</h4>
    <badge type="primary"> Javascript </badge>
    <badge type="rose"> CSS </badge>
    <badge type="info"> VUE JS </badge>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import { Badge } from "@/components";

export default {
  components: { Badge },
  mixins: [Mixins.HeaderImage],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss"></style>
