<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">Daftar Member</h1>
            <h4>
              Terdapat <b>10 member</b> yang aktif dan resmi pada komunitas PETISI
              Kalimantan Selatan.
            </h4>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto text-center">
              <tabs
                pills-align="center"
                :tab-active="1"
                :tab-name="['Member Resmi', 'Pengurus']"
                plain
                color-button="secondcolor"
              >
                <!-- here you can add your content for tab-content -->
                <template slot="tab-pane-1">
                  <!-- content daftar event -->
                  <div class="container">
                    <div class="md-layout">
                      <!-- begin filter -->
                      <div class="md-layout-item md-size-25 md-small-size-100">
                        <md-card class="md-card md-card-refine md-card-plain">
                          <div class="md-collapse">
                            <div class="md-autocomplete">
                              <md-autocomplete
                                v-model="selectedEmployee"
                                class="search has-black"
                                :md-options="employees"
                                :md-open-on-focus="false"
                              >
                                <label>Search...</label>
                              </md-autocomplete>
                            </div>
                          </div>
                          <md-card-content>
                            <h4 class="card-title">
                              Filter
                              <md-button class="md-just-icon md-simple md-sm">
                                <md-icon>cached</md-icon>
                                <md-tooltip md-direction="top"> Reset Filter </md-tooltip>
                              </md-button>
                            </h4>
                            <collapse
                              :active-tab="1"
                              :collapse="['Keahlian']"
                              icon="keyboard_arrow_down"
                              color-collapse="rose"
                            >
                              <!-- begin item keahlian -->
                              <template slot="md-collapse-pane-1">
                                <md-checkbox v-model="clothing.blazers" class="mb-0">
                                  PHP
                                </md-checkbox>
                                <md-checkbox v-model="clothing.casualShirts" class="mb-0">
                                  Laravel
                                </md-checkbox>
                                <md-checkbox v-model="clothing.formalShirts" class="mb-0">
                                  CSS
                                </md-checkbox>
                                <md-checkbox v-model="clothing.jeans" class="mb-0">
                                  HTML
                                </md-checkbox>
                                <md-checkbox v-model="clothing.polos" class="mb-0">
                                  VUE JS
                                </md-checkbox>
                                <md-checkbox v-model="clothing.pyjamas" class="mb-0">
                                  Sysadmin
                                </md-checkbox>
                                <md-checkbox v-model="clothing.shorts" class="mb-0">
                                  Linux
                                </md-checkbox>
                                <md-checkbox v-model="clothing.trousers" class="mb-0">
                                  Flutter
                                </md-checkbox>
                              </template>
                              <!-- end item keahlian -->
                            </collapse>
                          </md-card-content>
                        </md-card>
                      </div>
                      <!-- end filter -->
                      <!-- begin content member -->
                      <div
                        class="md-layout-item md-size-75 md-small-size-100"
                        style="margin-top: 80px"
                      >
                        <div class="md-layout">
                          <div
                            class="md-layout-item md-size-30 md-small-size-100"
                            v-for="(dataMembers, index) in dataMembers"
                            :key="index"
                          >
                            <profile-card
                              card-plain
                              card-avatar
                              :shadow-normal="false"
                              :no-colored-shadow="false"
                              :card-image="profileImageDefault"
                            >
                              <template slot="cardContent">
                                <h4 class="card-title">{{ dataMembers.name }}</h4>
                                <h6 class="card-category text-muted">
                                  {{ dataMembers.email }}
                                </h6>
                                <p class="card-description">
                                  {{ dataMembers.domicile }}
                                </p>
                              </template>
                              <template slot="cardAction">
                                <md-button
                                  href="javascript:void(0)"
                                  class="md-twitter md-just-icon md-simple"
                                >
                                  <i class="fab fa-twitter" />
                                </md-button>
                                <md-button
                                  href="javascript:void(0)"
                                  class="md-facebook md-just-icon md-simple"
                                >
                                  <i class="fab fa-facebook" />
                                </md-button>
                                <md-button
                                  href="javascript:void(0)"
                                  class="md-google md-just-icon md-simple"
                                >
                                  <i class="fab fa-google" />
                                </md-button>
                              </template>
                            </profile-card>
                          </div>
                        </div>
                      </div>
                      <div class="md-layout-item md-size-25 md-small-size-100 mx-auto">
                        <pagination
                          v-model="infoPagination"
                          class="pagination-secondcolor"
                          with-text
                          :page-count="page.current_page"
                        />
                      </div>
                      <!-- end content member -->
                    </div>
                  </div>
                </template>
                <template slot="tab-pane-2">
                  <!-- content pengurus -->
                  <!-- begin content member -->
                  <div
                    class="md-layout-item md-size-100 md-small-size-100"
                    style="margin-top: 80px"
                  >
                    <div class="md-layout">
                      <div
                        class="md-layout-item md-size-33 md-small-size-100"
                        v-for="(dataMembers, index) in dataMembers"
                        :key="index"
                      >
                        <profile-card
                          card-plain
                          card-avatar
                          :shadow-normal="false"
                          :no-colored-shadow="false"
                          :card-image="profileImageDefault"
                        >
                          <template slot="cardContent">
                            <h4 class="card-title">{{ dataMembers.name }}</h4>
                            <h6 class="card-category text-muted">
                              {{ dataMembers.email }}
                            </h6>
                            <p class="card-description">
                              {{ dataMembers.domicile }}
                            </p>
                          </template>
                          <template slot="cardAction">
                            <md-button
                              href="javascript:void(0)"
                              class="md-twitter md-just-icon md-simple"
                            >
                              <i class="fab fa-twitter" />
                            </md-button>
                            <md-button
                              href="javascript:void(0)"
                              class="md-facebook md-just-icon md-simple"
                            >
                              <i class="fab fa-facebook" />
                            </md-button>
                            <md-button
                              href="javascript:void(0)"
                              class="md-google md-just-icon md-simple"
                            >
                              <i class="fab fa-google" />
                            </md-button>
                          </template>
                        </profile-card>
                      </div>
                      <!-- <div
                        class="md-layout-item md-size-33 md-small-size-100"
                        v-for="(cardProfile, index) in cardProfile"
                        :key="index"
                      >
                        <profile-card
                          card-plain
                          card-avatar
                          :shadow-normal="false"
                          :no-colored-shadow="false"
                          :card-image="cardProfile"
                        >
                          <template slot="cardContent">
                            <h4 class="card-title">Alec Thompson</h4>
                            <h6 class="card-category text-muted">BACKEND DEVELOPER</h6>
                            <p class="card-description">
                              And I love you like Kanye loves Kanye. We need to restart
                              the human foundation.
                            </p>
                          </template>
                          <template slot="cardAction">
                            <md-button
                              href="javascript:void(0)"
                              class="md-twitter md-just-icon md-simple"
                            >
                              <i class="fab fa-twitter" />
                            </md-button>
                            <md-button
                              href="javascript:void(0)"
                              class="md-facebook md-just-icon md-simple"
                            >
                              <i class="fab fa-facebook" />
                            </md-button>
                            <md-button
                              href="javascript:void(0)"
                              class="md-google md-just-icon md-simple"
                            >
                              <i class="fab fa-google" />
                            </md-button>
                          </template>
                        </profile-card>
                      </div> -->
                    </div>
                  </div>
                  <div class="md-layout-item md-size-25 md-small-size-100 mx-auto">
                    <pagination
                      v-model="infoPagination"
                      class="pagination-secondcolor"
                      with-text
                      :page-count="5"
                    />
                  </div>
                  <!-- end content member -->
                </template>
              </tabs>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
    <div class="section section-blog">
      <div class="container">
        <h2 class="section-title">Event Populer</h2>
        <div class="md-layout">
          <div class="md-layout-item md-size-25 md-small-size-100">
            <blog-card
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="cardBlog.cardBlog1"
            >
              <template slot="cardContent">
                <h6 class="card-category text-rose">Event Diskusi</h6>
                <div style="display: flex; justify-content: space-between">
                  <span>Rp.12.00</span>
                  <span>20/02/2022 15:00</span>
                </div>

                <div style="margin-top: 10px">
                  <md-icon>place</md-icon>
                  <p class="card-description">
                    Cangkir Kopi. Mahligai, pal 7 Banjarmasin
                  </p>
                </div>
              </template>
              <template slot="cardAction">
                <div class="price-container">
                  <md-icon>people_outline</md-icon
                  ><span class="price price-new"> sisa 17</span>
                </div>
                <div class="ml-auto">
                  <router-link
                    :to="{
                      name: 'detail-event',
                      params: { uuid: '123' },
                    }"
                  >
                    <md-button class="md-info md-round md-sm">
                      <md-icon>info</md-icon> Detail
                      <md-tooltip md-direction="top"> Detail Event </md-tooltip>
                    </md-button>
                  </router-link>
                </div>
              </template>
            </blog-card>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-100">
            <blog-card
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="cardBlog.cardBlog2"
            >
              <template slot="cardContent">
                <h6 class="card-category text-rose">Event Diskusi</h6>
                <div style="display: flex; justify-content: space-between">
                  <span>Rp.12.00</span>
                  <span>20/02/2022 15:00</span>
                </div>

                <div style="margin-top: 10px">
                  <md-icon>place</md-icon>
                  <p class="card-description">
                    Cangkir Kopi. Mahligai, pal 7 Banjarmasin
                  </p>
                </div>
              </template>
              <template slot="cardAction">
                <div class="price-container">
                  <md-icon>people_outline</md-icon
                  ><span class="price price-new"> sisa 17</span>
                </div>
                <div class="ml-auto">
                  <router-link
                    :to="{
                      name: 'detail-event',
                      params: { uuid: '123' },
                    }"
                  >
                    <md-button class="md-info md-round md-sm">
                      <md-icon>info</md-icon> Detail
                      <md-tooltip md-direction="top"> Detail Event </md-tooltip>
                    </md-button>
                  </router-link>
                </div>
              </template>
            </blog-card>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-100">
            <blog-card
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="cardBlog.cardBlog3"
            >
              <template slot="cardContent">
                <h6 class="card-category text-rose">Event Diskusi</h6>
                <div style="display: flex; justify-content: space-between">
                  <span>Rp.12.00</span>
                  <span>20/02/2022 15:00</span>
                </div>

                <div style="margin-top: 10px">
                  <md-icon>place</md-icon>
                  <p class="card-description">
                    Cangkir Kopi. Mahligai, pal 7 Banjarmasin
                  </p>
                </div>
              </template>
              <template slot="cardAction">
                <div class="price-container">
                  <md-icon>people_outline</md-icon
                  ><span class="price price-new"> sisa 17</span>
                </div>
                <div class="ml-auto">
                  <router-link
                    :to="{
                      name: 'detail-event',
                      params: { uuid: '123' },
                    }"
                  >
                    <md-button class="md-info md-round md-sm">
                      <md-icon>info</md-icon> Detail
                      <md-tooltip md-direction="top"> Detail Event </md-tooltip>
                    </md-button>
                  </router-link>
                </div>
              </template>
            </blog-card>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-100">
            <blog-card
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="cardBlog.cardBlog3"
            >
              <template slot="cardContent">
                <h6 class="card-category text-rose">Event Diskusi</h6>
                <div style="display: flex; justify-content: space-between">
                  <span>Rp.12.00</span>
                  <span>20/02/2022 15:00</span>
                </div>

                <div style="margin-top: 10px">
                  <md-icon>place</md-icon>
                  <p class="card-description">
                    Cangkir Kopi. Mahligai, pal 7 Banjarmasin
                  </p>
                </div>
              </template>
              <template slot="cardAction">
                <div class="price-container">
                  <md-icon>people_outline</md-icon
                  ><span class="price price-new"> sisa 17</span>
                </div>
                <div class="ml-auto">
                  <router-link
                    :to="{
                      name: 'detail-event',
                      params: { uuid: '123' },
                    }"
                  >
                    <md-button class="md-info md-round md-sm">
                      <md-icon>info</md-icon> Detail
                      <md-tooltip md-direction="top"> Detail Event </md-tooltip>
                    </md-button>
                  </router-link>
                </div>
              </template>
            </blog-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET } from "@/services/request_api.module";

import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import {
  Tabs,
  ProductCard,
  Collapse,
  FullBgCard,
  BlogCard,
  Slider,
  Pagination,
} from "@/components";
import Mixins from "@/plugins/basicMixins";

import { ProfileCard } from "@/components";

export default {
  name: "dashboardDaftarMember",
  components: {
    ProductCard,
    Collapse,
    FullBgCard,
    BlogCard,
    Slider,
    Tabs,
    Pagination,
    FullCalendar,
    ProfileCard,
  },
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      dataMembers: [],
      page: {},
      profileImageDefault: require("@/assets/img/faces/Unknown_person.jpeg"),
      infoPagination: "",
      //  ===========================================

      subscribe: null,
      sliders: {
        rangeSlider: [5000, 500000],
      },
      clothing: {
        blazers: true,
        casualShirts: false,
        formalShirts: false,
        jeans: false,
        polos: false,
        pyjamas: false,
        shorts: false,
        trousers: false,
      },
      designer: {
        all: true,
        polo: false,
        wooyoungmi: false,
        alexander: false,
        tom: false,
        ami: false,
        berena: false,
        sweeney: false,
        burberry: false,
        calvin: false,
        kingsman: false,
        monaco: false,
        dolce: false,
        gucci: false,
        biglioli: false,
        lanvin: false,
        piana: false,
        massimo: false,
      },
      colour: {
        all: true,
        black: false,
        blue: false,
        brown: false,
        gray: false,
        green: false,
        neutrals: false,
        purple: false,
      },
      image: require("@/assets/img/petisi/bg2.jpeg"),
      image2: require("@/assets/img/petisi/bg1.jpg"),
      productCard: {
        productCard1: require("@/assets/img/examples/gucci.jpg"),
        productCard2: require("@/assets/img/examples/dolce.jpg"),
        productCard3: require("@/assets/img/examples/tom-ford.jpg"),
        productCard4: require("@/assets/img/examples/suit-1.jpg"),
        productCard5: require("@/assets/img/examples/suit-2.jpg"),
        productCard6: require("@/assets/img/examples/suit-3.jpg"),
        productCard7: require("@/assets/img/examples/suit-4.jpg"),
        productCard8: require("@/assets/img/examples/suit-5.jpg"),
        productCard9: require("@/assets/img/examples/suit-6.jpg"),
      },
      cardBg: {
        cardBg1: require("@/assets/img/examples/color1.jpg"),
        cardBg2: require("@/assets/img/examples/color3.jpg"),
        cardBg3: require("@/assets/img/examples/color2.jpg"),
        cardBg4: require("@/assets/img/dg3.jpg"),
        cardBg5: require("@/assets/img/dg1.jpg"),
      },
      cardBlog: {
        cardBlog1: require("@/assets/img/dg6.jpg"),
        cardBlog2: require("@/assets/img/dg10.jpg"),
        cardBlog3: require("@/assets/img/dg9.jpg"),
      },
      infoPagination: 3,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
      },
      cardProfile: {
        cardProfile1: require("@/assets/img/faces/marc.jpg"),
        cardProfile2: require("@/assets/img/faces/kendall.jpg"),
        cardProfile3: require("@/assets/img/faces/christian.jpg"),
        cardProfile4: require("@/assets/img/faces/avatar.jpg"),
        cardProfile5: require("@/assets/img/faces/marc.jpg"),
        cardProfile6: require("@/assets/img/faces/kendall.jpg"),
        cardProfile7: require("@/assets/img/faces/christian.jpg"),
        cardProfile8: require("@/assets/img/faces/avatar.jpg"),
        cardProfile9: require("@/assets/img/faces/marc.jpg"),
        cardProfile10: require("@/assets/img/faces/kendall.jpg"),
        cardProfile11: require("@/assets/img/faces/christian.jpg"),
        cardProfile12: require("@/assets/img/faces/avatar.jpg"),
      },
      selectedEmployee: "",
      employees: [
        "Jim Halpert",
        "Dwight Schrute",
        "Michael Scott",
        "Pam Beesly",
        "Angela Martin",
        "Kelly Kapoor",
        "Ryan Howard",
        "Kevin Malone",
      ],
    };
  },
  watch: {
    infoPagination(val) {
      // simpan ke po dan poitem
      this.getDataMembers(val)
    },
  },
  computed: {
    imageSubscribe() {
      return {
        backgroundImage: `url(${this.image2})`,
      };
    },
  },
  mounted() {
    this.getDataMembers();
  },
  methods: {
    newValue(e) {
      this.sliders.rangeSlider[0] = e[0];
      this.sliders.rangeSlider[1] = e[1];
    },
    async getDataMembers(page = 1) {
      const url = `member/list${page != 1 ? '?page='+page : ''}`;
      await this.$store.dispatch(GET, { url }).then(async (res) => {
        // console.log(res);
        this.dataMembers = res.data;
        this.page = res.meta
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mt-3 {
  margin-top: 1.875rem * 2;
}
.mb-0 {
  margin-bottom: 0;
}
</style>
