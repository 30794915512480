<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      filter-color="primarycolor"
      :style="headerStyle"
    >
    </parallax>
    <div class="section">
      <div class="container">
        <div class="main main-raised">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <div class="md-layout">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <img
                    class="img-raised rounded img-fluid"
                    alt="Raised Image"
                    :src="blogPost.blogPost1"
                  />
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <h2 class="title">SEMINAR NASIONAL TEKNOLOGI INFORMASI</h2>
              <div style="display: flex; justify-content: space-between">
                <span>Rp.12.00</span>
                <span>20/02/2022 15:00</span>
              </div>
              <div style="margin-top: 20px; text-align: right">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry’s standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book. It has survived not only five
                  centuries, but also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with the release
                  of Letraset sheets containing Lorem Ipsum passages, and more recently
                  with desktop publishing software like Aldus PageMaker including versions
                  of Lorem Ipsum.
                </p>
              </div>
              <div style="margin-top: 10px; text-align: right">
                <md-icon>place</md-icon>
                <p class="card-description">Cangkir Kopi. Mahligai, pal 7 Banjarmasin</p>
              </div>

              <div
                class="pick-size"
                style="display: flex; justify-content: space-between"
              >
                <div>
                  <md-icon>people_outline</md-icon
                  ><span class="price price-new"> sisa 17</span>
                </div>
                <div class="ml-auto">
                  <md-button class="md-success md-round" @click="classicModal = true">
                    <md-icon>how_to_reg</md-icon> Daftar
                    <md-tooltip md-direction="top"> Daftar Event Ini </md-tooltip>
                  </md-button>
                  <!-- begin modal pendaftaran -->
                  <div class="modal modal-bg" :class="{ show: classicModal }">
                    <modal v-if="classicModal" @close="classicModal = false">
                      <template slot="header">
                        <div class="md-layout" style="text-align: center">
                          <div class="md-layout-item md-size-100">
                            <h2 class="modal-title">Konfirmasi Pendaftaran Event</h2>
                          </div>
                          <div class="md-layout-item md-size-100">
                            <h4 class="modal-title">
                              SEMINAR NASIONAL TEKNOLOGI INFORMASI
                            </h4>
                          </div>
                        </div>
                        <md-button
                          class="md-simple md-close md-just-icon md-round modal-default-button"
                          @click="classicModal = false"
                        >
                          <md-icon>clear</md-icon>
                        </md-button>
                      </template>

                      <template slot="body">
                        <div class="instruction">
                          <div class="md-layout">
                            <div class="md-layout-item md-size-100">
                              <strong>Persyaratan</strong>
                              <p class="description">
                                The first step is to create an account at
                                <a href="https://www.creative-tim.com/">Creative Tim</a>.
                                You can choose a social network or go for the classic
                                version, whatever works best for you.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="instruction">
                          <div class="md-layout">
                            <div class="md-layout-item md-size-100">
                              <strong>Peraturan</strong>
                              <p class="description">
                                The first step is to create an account at
                                <a href="https://www.creative-tim.com/">Creative Tim</a>.
                                You can choose a social network or go for the classic
                                version, whatever works best for you.
                              </p>
                            </div>
                          </div>
                        </div>
                        <p>
                          <md-checkbox v-model="ketentuan">
                            Apakah anda setuju dengan syarat diatas?
                          </md-checkbox>
                        </p>
                      </template>

                      <template slot="footer">
                        <md-button class="md-danger md-simple"  @click="classicModal = false"> Batal </md-button>
                        <md-button class="md-info md-round"> Daftar </md-button>
                      </template>
                    </modal>
                  </div>
                  <!-- end modal pendaftaran -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- begin sponsor -->
        <div class="features text-center">
          <div class="md-layout">
            <div
              class="md-layout-item md-size-33 md-small-size-100"
              style="margin-top: 100px"
            >
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfile.cardProfile1"
              >
                <template slot="cardContent">
                  <h4 class="card-title">PHP KALSEL</h4>
                </template>
              </profile-card>
            </div>
            <div
              class="md-layout-item md-size-33 md-small-size-100"
              style="margin-top: 100px"
            >
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfile.cardProfile2"
              >
                <template slot="cardContent">
                  <h4 class="card-title">POLDA KALIMANTAN SELATAN</h4>
                </template>
              </profile-card>
            </div>
            <div
              class="md-layout-item md-size-33 md-small-size-100"
              style="margin-top: 100px"
            >
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfile.cardProfile3"
              >
                <template slot="cardContent">
                  <h4 class="card-title">BEKANTAN JANTAN</h4>
                </template>
              </profile-card>
            </div>
          </div>
        </div>
        <!-- end sponsor -->
        <!-- begin event populer -->
        <div class="section section-blog">
          <div class="container">
            <h2 class="section-title">Event Populer</h2>
            <div class="md-layout">
              <div class="md-layout-item md-size-25 md-small-size-100">
                <blog-card
                  :shadow-normal="false"
                  :no-colored-shadow="false"
                  :card-image="productCard.productCard1"
                >
                  <template slot="cardContent">
                    <h6 class="card-category text-rose">Event Diskusi</h6>
                    <!-- <div style="display: flex; justify-content: space-between"> -->
                    <span>Rp.12.00</span><br />
                    <span>20/02/2022 15:00</span>
                    <!-- </div> -->

                    <div style="margin-top: 10px">
                      <md-icon>place</md-icon>
                      <p class="card-description">
                        Cangkir Kopi. Mahligai, pal 7 Banjarmasin
                      </p>
                    </div>
                  </template>
                  <template slot="cardAction">
                    <div class="price-container">
                      <md-icon>people_outline</md-icon><br /><span
                        class="price price-new"
                      >
                        sisa 17</span
                      >
                    </div>
                    <div class="ml-auto">
                      <router-link
                        :to="{
                          name: 'detail-event',
                          params: { uuid: '123' },
                        }"
                      >
                        <md-button class="md-info md-round md-sm">
                          <md-icon>info</md-icon> Detail
                          <md-tooltip md-direction="top"> Detail Event </md-tooltip>
                        </md-button>
                      </router-link>
                    </div>
                  </template>
                </blog-card>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <blog-card
                  :shadow-normal="false"
                  :no-colored-shadow="false"
                  :card-image="productCard.productCard2"
                >
                  <template slot="cardContent">
                    <h6 class="card-category text-rose">Event Diskusi</h6>
                    <!-- <div style="display: flex; justify-content: space-between"> -->
                    <span>Rp.12.00</span><br />
                    <span>20/02/2022 15:00</span>
                    <!-- </div> -->

                    <div style="margin-top: 10px">
                      <md-icon>place</md-icon>
                      <p class="card-description">
                        Cangkir Kopi. Mahligai, pal 7 Banjarmasin
                      </p>
                    </div>
                  </template>
                  <template slot="cardAction">
                    <div class="price-container">
                      <md-icon>people_outline</md-icon><br /><span
                        class="price price-new"
                      >
                        sisa 17</span
                      >
                    </div>
                    <div class="ml-auto">
                      <router-link
                        :to="{
                          name: 'detail-event',
                          params: { uuid: '123' },
                        }"
                      >
                        <md-button class="md-info md-round md-sm">
                          <md-icon>info</md-icon> Detail
                          <md-tooltip md-direction="top"> Detail Event </md-tooltip>
                        </md-button>
                      </router-link>
                    </div>
                  </template>
                </blog-card>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <blog-card
                  :shadow-normal="false"
                  :no-colored-shadow="false"
                  :card-image="productCard.productCard3"
                >
                  <template slot="cardContent">
                    <h6 class="card-category text-rose">Event Diskusi</h6>
                    <!-- <div style="display: flex; justify-content: space-between"> -->
                    <span>Rp.12.00</span><br />
                    <span>20/02/2022 15:00</span>
                    <!-- </div> -->

                    <div style="margin-top: 10px">
                      <md-icon>place</md-icon>
                      <p class="card-description">
                        Cangkir Kopi. Mahligai, pal 7 Banjarmasin
                      </p>
                    </div>
                  </template>
                  <template slot="cardAction">
                    <div class="price-container">
                      <md-icon>people_outline</md-icon><br /><span
                        class="price price-new"
                      >
                        sisa 17</span
                      >
                    </div>
                    <div class="ml-auto">
                      <router-link
                        :to="{
                          name: 'detail-event',
                          params: { uuid: '123' },
                        }"
                      >
                        <md-button class="md-info md-round md-sm">
                          <md-icon>info</md-icon> Detail
                          <md-tooltip md-direction="top"> Detail Event </md-tooltip>
                        </md-button>
                      </router-link>
                    </div>
                  </template>
                </blog-card>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <blog-card
                  :shadow-normal="false"
                  :no-colored-shadow="false"
                  :card-image="productCard.productCard3"
                >
                  <template slot="cardContent">
                    <h6 class="card-category text-rose">Event Diskusi</h6>
                    <!-- <div style="display: flex; justify-content: space-between"> -->
                    <span>Rp.12.00</span><br />
                    <span>20/02/2022 15:00</span>
                    <!-- </div> -->

                    <div style="margin-top: 10px">
                      <md-icon>place</md-icon>
                      <p class="card-description">
                        Cangkir Kopi. Mahligai, pal 7 Banjarmasin
                      </p>
                    </div>
                  </template>
                  <template slot="cardAction">
                    <div class="price-container">
                      <md-icon>people_outline</md-icon><br /><span
                        class="price price-new"
                      >
                        sisa 17</span
                      >
                    </div>
                    <div class="ml-auto">
                      <router-link
                        :to="{
                          name: 'detail-event',
                          params: { uuid: '123' },
                        }"
                      >
                        <md-button class="md-info md-round md-sm">
                          <md-icon>info</md-icon> Detail
                          <md-tooltip md-direction="top"> Detail Event </md-tooltip>
                        </md-button>
                      </router-link>
                    </div>
                  </template>
                </blog-card>
              </div>
            </div>
          </div>
        </div>
        <!-- end event populer -->
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse, InfoAreas, BlogCard, Modal } from "@/components";
import Mixins from "@/plugins/basicMixins";
import { ProfileCard } from "@/components";

export default {
  components: {
    Collapse,
    InfoAreas,
    BlogCard,
    ProfileCard,
    Modal,
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "product-page",
  data() {
    return {
      selectColor: "rose",
      selectSize: "small",
      image: require("@/assets/img/petisi/bg2.jpeg"),
      productCard: {
        productCard1: require("@/assets/img/examples/gucci.jpg"),
        productCard2: require("@/assets/img/examples/dolce.jpg"),
        productCard3: require("@/assets/img/examples/tom-ford.jpg"),
        productCard4: require("@/assets/img/examples/suit-1.jpg"),
        productCard5: require("@/assets/img/examples/suit-2.jpg"),
        productCard6: require("@/assets/img/examples/suit-3.jpg"),
        productCard7: require("@/assets/img/examples/suit-4.jpg"),
        productCard8: require("@/assets/img/examples/suit-5.jpg"),
        productCard9: require("@/assets/img/examples/suit-6.jpg"),
      },
      blogPost: {
        blogPost1: require("@/assets/img/examples/blog4.jpg"),
        blogPost2: require("@/assets/img/examples/blog3.jpg"),
        blogPost3: require("@/assets/img/examples/blog1.jpg"),
      },
      cardProfile: {
        cardProfile1: require("@/assets/img/petisi/phpid_kalsel.png"),
        cardProfile2: require("@/assets/img/petisi/poldakalsel.png"),
        cardProfile3: require("@/assets/img/petisi/bekantanjantan.png"),
      },
      classicModal: false,
    };
  },
};
</script>
<style lang="scss">
.inline-container-zoomer-box {
  .preview-box {
    text-align: center;
    margin-bottom: 5vh;
    margin-top: 2vh;

    img {
      max-width: 60%;
    }
  }

  .control-box {
    margin-bottom: 30px;
  }

  .thumb-list img {
    padding: 18px 8px;
    border: 1px solid transparent;
    opacity: 0.8;
    transition: all 0.15s ease;

    &:hover {
      opacity: 1;
      border-color: #ddd;
    }
  }

  .responsive-image {
    max-width: 80%;
    border-radius: 3px;

    &.choosed-thumb {
      box-shadow: none !important;
      border-color: #ddd;
    }
  }

  .control {
    font-size: 14px;

    path {
      fill: #9fa4ab;
    }
  }
}
</style>
