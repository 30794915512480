import { render, staticRenderFns } from "./formCardPendaftaran.vue?vue&type=template&id=966f35e0&scoped=true&"
import script from "./formCardPendaftaran.vue?vue&type=script&lang=js&"
export * from "./formCardPendaftaran.vue?vue&type=script&lang=js&"
import style0 from "./formCardPendaftaran.vue?vue&type=style&index=0&id=966f35e0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "966f35e0",
  null
  
)

export default component.exports