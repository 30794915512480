<template>
  <div class="wrapper">
    <!-- begin patner -->
    <div class="section section-team-1">
      <div class="container">
        <!-- <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h2 class="title">Our Awesome Team 1</h2>
            <h5 class="description">
              This is the paragraph where you can write more details about your team. Keep
              you user engaged by providing meaningful information.
            </h5>
          </div>
        </div> -->
        <div class="md-layout">
          <div class="md-layout-item md-size-33 md-small-size-100">
            <profile-card
              card-plain
              card-avatar
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="cardProfile.cardProfile1"
            >
              <template slot="cardContent">
                <h4 class="card-title">PHP KALSEL</h4>
              </template>
            </profile-card>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <profile-card
              card-plain
              card-avatar
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="cardProfile.cardProfile2"
            >
              <template slot="cardContent">
                <h4 class="card-title">POLDA KALIMANTAN SELATAN</h4>
              </template>
            </profile-card>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <profile-card
              card-plain
              card-avatar
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="cardProfile.cardProfile3"
            >
              <template slot="cardContent">
                <h4 class="card-title">BEKANTANJANTAN</h4>
              </template>
            </profile-card>
          </div>
        </div>
      </div>
    </div>
    <!-- end patner -->
  </div>
</template>

<script>
import { ProfileCard } from "@/components";
export default {
  name: "Teams",
  components: {
    ProfileCard,
  },
  data() {
    return {
      cardProfile: {
        cardProfile1: require("@/assets/img/petisi/phpid_kalsel.png"),
        cardProfile2: require("@/assets/img/petisi/poldakalsel.png"),
        cardProfile3: require("@/assets/img/petisi/bekantanjantan.png")
      },
    };
  },
  methods: {
  },
};
</script>

<style lang="scss"></style>
