<template>
  <div class="wrapper">
    <parallax
      class="section page-header header-filter"
      parallax-active="true"
      :style="headerStyle"
    />
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
              <div class="profile">
                <div class="avatar">
                  <img
                    :src="img"
                    alt="Circle Image"
                    class="img-raised rounded-circle img-fluid"
                  />
                </div>
                <div class="name">
                  <h3 class="title">Carla Hortensia</h3>
                  <h6>Designer</h6>
                  <md-button
                    href="javascript:void(0)"
                    class="md-just-icon md-simple md-dribbble"
                  >
                    <i class="fab fa-dribbble" />
                  </md-button>
                  <md-button
                    href="javascript:void(0)"
                    class="md-just-icon md-simple md-twitter"
                  >
                    <i class="fab fa-twitter" />
                  </md-button>
                  <md-button
                    href="javascript:void(0)"
                    class="md-just-icon md-simple md-pinterest"
                  >
                    <i class="fab fa-pinterest" />
                  </md-button>
                </div>
                <!-- <div class="follow">
                  <md-button class="md-success md-just-icon md-round">
                    <md-icon>edit</md-icon>
                    <md-tooltip md-direction="top"> Follow this user </md-tooltip>
                  </md-button>
                </div> -->
              </div>
            </div>
          </div>
          <div class="description text-center">
            <p>
              An artist of considerable range, Chet Faker — the name taken by
              Melbourne-raised, Brooklyn-based Nick Murphy — writes, performs and records
              all of his own music, giving it a warm, intimate feel with a solid groove
              structure.
            </p>
          </div>
          <div class="profile-tabs">
            <tabs
              :tab-active="1"
              :tab-name="['Keahlian', 'Portofolio']"
              :tab-icon="['settings_accessibility', 'leaderboard']"
              plain
              nav-pills-icons
              color-button="secondcolor"
            >
              <!-- here you can add your content for tab-content -->
              <template slot="tab-pane-1">
                <div class="md-layout work">
                  <div class="md-layout-item md-size-60 md-small-size-100 mx-auto">
                    <h4 class="title">Keahlian</h4>
                    <md-button href="javascript:void(0)" class="md-secondcolor md-round md-sm">
                      <md-icon>add</md-icon> Tambah...
                    </md-button>
                    <div class="md-layout collections">
                      <div
                        class="md-layout-item md-size-50 md-small-size-100"
                        v-for="(blogPost, index) in blogPost"
                        :key="index"
                      >
                        <rotating-card
                          :manual-rotate-state="manualRotateState"
                          manual-rotate
                        >
                          <template slot="frontRotateCardContent">
                            <!-- <h5 class="card-category card-category-social text-success">
                              <i class="far fa-newspaper" /> Backend PHP Native
                            </h5> -->
                            <h4 class="card-title">
                              <a href="javascript:void(0)">"Backend PHP Native"</a>
                            </h4>
                            <span>IT</span>
                            <p class="card-description">
                              Don't be scared of the truth because we need to restart the
                              human foundation in truth And I love you like Kanye loves
                              Kanye I love Rick Owens’ bed design but the back is...
                            </p>
                            <div style="text-align: left">
                              <badge style="margin-right: 5px" type="info">
                                Beginer
                              </badge>
                            </div>
                            <div class="stats text-center" style="margin-top: 20px">
                              <md-button
                                href="javascript:void(0)"
                                class="md-simple md-round md-sm"
                              >
                                <md-icon>edit</md-icon> Edit...
                              </md-button>
                              <md-button
                                href="javascript:void(0)"
                                class="md-success md-round md-sm"
                                @click="manualRotateState = true"
                              >
                                <md-icon>refresh</md-icon> Rotate...
                              </md-button>
                            </div>
                          </template>
                          <template slot="backRotateCardContent">
                            <h5 class="card-title">Do more...</h5>
                            <p class="card-description">
                              You can read this article or share it with your friends and
                              family on different networks...
                            </p>
                            <div class="stats text-center">
                              <md-button
                                href="javascript:void(0)"
                                class="md-rose md-round"
                              >
                                <md-icon>subject</md-icon> Read
                              </md-button>
                              <md-button
                                href="javascript:void(0)"
                                class="md-twitter md-just-icon md-round"
                              >
                                <i class="fab fa-twitter" />
                              </md-button>
                              <md-button
                                href="javascript:void(0)"
                                class="md-dribbble md-just-icon md-round"
                              >
                                <i class="fab fa-dribbble" />
                              </md-button>
                              <md-button
                                href="javascript:void(0)"
                                class="md-facebook md-just-icon md-round"
                              >
                                <i class="fab fa-facebook" />
                              </md-button>
                            </div>
                            <br />
                            <md-button
                              href="javascript:void(0)"
                              class="md-simple md-round"
                              @click="manualRotateState = false"
                            >
                              <md-icon>refresh</md-icon> Back...
                            </md-button>
                          </template>
                        </rotating-card>
                      </div>
                    </div>
                  </div>
                  <div class="md-layout-item md-size-20 md-small-size-100 mx-auto stats">
                    <biodata />
                  </div>
                </div>
              </template>
              <template slot="tab-pane-2">
                <div class="md-layout work">
                  <div class="md-layout-item md-size-70 md-small-size-100 mx-auto">
                    <h4 class="title">Portofolio</h4>
                    <div class="md-layout collections">
                      <div
                        class="md-layout-item md-size-50 md-small-size-100"
                        v-for="(blogPost, index) in blogPost"
                        :key="index"
                      >
                        <rotating-card
                          :manual-rotate-state="manualRotateState"
                          manual-rotate
                        >
                          <template slot="frontRotateCardContent">
                            <div class="md-layout">
                              <div class="md-layout-item md-size-100 md-small-size-100">
                                <img
                                  class="img-raised rounded img-fluid"
                                  alt="Raised Image"
                                  :src="blogPost"
                                />
                                <h4 class="card-title">
                                  <a href="javascript:void(0)">"Aplikasi Todolist"</a>
                                </h4>
                                <badge style="margin-left: 5px" type="info"> WEB </badge>
                                <p></p>
                              </div>
                              <div class="md-layout-item md-size-100 md-small-size-100">
                                <div class="stats text-center">
                                  <md-button
                                    href="javascript:void(0)"
                                    class="md-simple md-round md-sm"
                                  >
                                    <md-icon>edit</md-icon> Edit...
                                  </md-button>
                                  <md-button
                                    href="javascript:void(0)"
                                    class="md-success md-round md-sm"
                                    @click="manualRotateState = true"
                                  >
                                    <md-icon>refresh</md-icon> Deskripsi...
                                  </md-button>
                                </div>
                              </div>
                            </div>
                          </template>
                          <template slot="backRotateCardContent">
                            <h5 class="card-title">Deksripsi...</h5>
                            <p class="card-description">
                              Material Kit PRO is coming with the famous colored shadows.
                              That means each image from the cards is getting an unique
                              color shadow. You don't have to do anything to activate
                              them, just enjoy the new look of your website.
                            </p>
                            <div class="stats text-center">
                              <md-button
                                href="javascript:void(0)"
                                class="md-rose md-round"
                              >
                                <md-icon>link</md-icon> Kunjungi
                              </md-button>
                            </div>
                            <br />
                            <md-button
                              href="javascript:void(0)"
                              class="md-simple md-round"
                              @click="manualRotateState = false"
                            >
                              <md-icon>refresh</md-icon> Back...
                            </md-button>
                          </template>
                        </rotating-card>
                      </div>
                    </div>
                  </div>
                  <div class="md-layout-item md-size-20 md-small-size-100 mx-auto stats">
                    <biodata />
                  </div>
                </div>
              </template>
            </tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tabs, FullBgCard, ProfileCard, Badge, RotatingCard } from "@/components";
import Mixins from "@/plugins/basicMixins";

// begin petisi section
import Biodata from "../petisiSections/biodata";
// end petisi section

export default {
  components: {
    Tabs,
    FullBgCard,
    ProfileCard,
    Badge,
    Biodata,
    RotatingCard,
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "profile-page",
  data() {
    return {
      image: require("@/assets/img/petisi/bg2.jpeg"),
      img: require("@/assets/img/faces/christian.jpg"),
      cardFullBg: {
        fullBg1: require("@/assets/img/examples/mariya-georgieva.jpg"),
        fullBg2: require("@/assets/img/examples/clem-onojeghuo.jpg"),
        fullBg3: require("@/assets/img/examples/olu-eletu.jpg"),
        fullBg4: require("@/assets/img/examples/floralia.jpg"),
      },
      cardProfile3: {
        cardProfile1: require("@/assets/img/faces/avatar.jpg"),
        cardProfile2: require("@/assets/img/faces/marc.jpg"),
        cardProfile3: require("@/assets/img/faces/kendall.jpg"),
        cardProfile4: require("@/assets/img/faces/card-profile2-square.jpg"),
      },
      tabPane1: [
        { image: require("@/assets/img/examples/studio-1.jpg") },
        { image: require("@/assets/img/examples/studio-2.jpg") },
        { image: require("@/assets/img/examples/studio-4.jpg") },
        { image: require("@/assets/img/examples/studio-5.jpg") },
      ],
      tabPane2: [
        { image: require("@/assets/img/examples/olu-eletu.jpg") },
        { image: require("@/assets/img/examples/clem-onojeghuo.jpg") },
        { image: require("@/assets/img/examples/cynthia-del-rio.jpg") },
        { image: require("@/assets/img/examples/mariya-georgieva.jpg") },
        { image: require("@/assets/img/examples/clem-onojegaw.jpg") },
      ],
      tabPane3: [
        { image: require("@/assets/img/examples/mariya-georgieva.jpg") },
        { image: require("@/assets/img/examples/studio-3.jpg") },
        { image: require("@/assets/img/examples/clem-onojeghuo.jpg") },
        { image: require("@/assets/img/examples/olu-eletu.jpg") },
        { image: require("@/assets/img/examples/studio-1.jpg") },
      ],
      manualRotateState: false,
      blogPost: {
        blogPost1: require("@/assets/img/examples/blog4.jpg"),
        blogPost2: require("@/assets/img/examples/blog3.jpg"),
        blogPost3: require("@/assets/img/examples/blog1.jpg"),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.section {
  padding: 0;
}

.profile-tabs::v-deep {
  .md-card-tabs .md-list {
    justify-content: center;
  }

  [class*="tab-pane-"] {
    margin-top: 3.213rem;
    padding-bottom: 50px;

    img:not(.img) {
      margin-bottom: 2.142rem;
    }
  }
}
</style>
