<template>
  <div class="wrapper">
    <!-- Team 5 -->
    <div class="section section-team-5 section-image" :style="sectionTeam(team5)">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h2 class="title">Pengurus Aktif</h2>
            <h5 class="description">
              Berikut pengurus aktif yang menghandle komunitas PETISI (Pegiat Teknologi Informasi dan Siber Indonesia).
            </h5>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-size-100">
            <profile-card
              card-plain
              type="horizontal"
              :shadow-normal="false"
              :no-colored-shadow="true"
              :card-image="cardProfile3.cardProfile1"
            >
              <template slot="cardContent">
                <h4 class="card-title">Akhmad Syarif</h4>
                <h6 class="card-category text-muted">Ketua</h6>
                <p class="card-description">
                  Don't be scared of the truth because we need to restart the human
                  foundation in truth...
                </p>
              </template>
              <template slot="cardAction">
                <md-button
                  href="javascript:void(0)"
                  class="md-twitter md-just-icon md-simple md-white"
                >
                  <i class="fab fa-twitter" />
                </md-button>
                <md-button
                  href="javascript:void(0)"
                  class="md-facebook md-just-icon md-simple md-white"
                >
                  <i class="fab fa-facebook" />
                </md-button>
                <md-button
                  href="javascript:void(0)"
                  class="md-google md-just-icon md-simple md-white"
                >
                  <i class="fab fa-google" />
                </md-button>
              </template>
            </profile-card>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <profile-card
              card-plain
              type="horizontal"
              :shadow-normal="false"
              :no-colored-shadow="true"
              :card-image="cardProfile3.cardProfile2"
            >
              <template slot="cardContent">
                <h4 class="card-title">Supian M</h4>
                <h6 class="card-category text-muted">Wakil Ketua</h6>
                <p class="card-description">
                  Don't be scared of the truth because we need to restart the human
                  foundation in truth...
                </p>
              </template>
              <template slot="cardAction">
                <md-button
                  href="javascript:void(0)"
                  class="md-linkedin md-just-icon md-simple md-white"
                >
                  <i class="fab fa-linkedin" />
                </md-button>
                <md-button
                  href="javascript:void(0)"
                  class="md-facebook md-just-icon md-simple md-white"
                >
                  <i class="fab fa-facebook" />
                </md-button>
                <md-button
                  href="javascript:void(0)"
                  class="md-dribbble md-just-icon md-simple md-white"
                >
                  <i class="fab fa-dribbble" />
                </md-button>
                <md-button
                  href="javascript:void(0)"
                  class="md-google md-just-icon md-simple md-white"
                >
                  <i class="fab fa-google" />
                </md-button>
              </template>
            </profile-card>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <profile-card
              card-plain
              type="horizontal"
              :shadow-normal="false"
              :no-colored-shadow="true"
              :card-image="cardProfile3.cardProfile3"
            >
              <template slot="cardContent">
                <h4 class="card-title">Luthfy Eka Permana</h4>
                <h6 class="card-category text-muted">Sekretaris</h6>
                <p class="card-description">
                  I love you like Kanye loves Kanye. Don't be scared of the truth.
                </p>
              </template>
              <template slot="cardAction">
                <md-button
                  href="javascript:void(0)"
                  class="md-youtube md-just-icon md-simple md-white"
                >
                  <i class="fab fa-youtube" />
                </md-button>
                <md-button
                  href="javascript:void(0)"
                  class="md-twitter md-just-icon md-simple md-white"
                >
                  <i class="fab fa-twitter" />
                </md-button>
                <md-button
                  href="javascript:void(0)"
                  class="md-instagram md-just-icon md-simple md-white"
                >
                  <i class="fab fa-instagram" />
                </md-button>
              </template>
            </profile-card>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <profile-card
              card-plain
              type="horizontal"
              :shadow-normal="false"
              :no-colored-shadow="true"
              :card-image="cardProfile3.cardProfile4"
            >
              <template slot="cardContent">
                <h4 class="card-title">Nafies Luthfi</h4>
                <h6 class="card-category text-muted">Bendahara</h6>
                <p class="card-description">
                  I love you like Kanye loves Kanye. Don't be scared of the truth.
                </p>
              </template>
              <template slot="cardAction">
                <md-button
                  href="javascript:void(0)"
                  class="md-linkedin md-just-icon md-simple md-white"
                >
                  <i class="fab fa-linkedin" />
                </md-button>
                <md-button
                  href="javascript:void(0)"
                  class="md-facebook md-just-icon md-simple md-white"
                >
                  <i class="fab fa-facebook" />
                </md-button>
                <md-button
                  href="javascript:void(0)"
                  class="md-google md-just-icon md-simple md-white"
                >
                  <i class="fab fa-google" />
                </md-button>
              </template>
            </profile-card>
          </div>
        </div>
      </div>
    </div>
    <!-- end Team 5 -->
  </div>
</template>

<script>
import { ProfileCard } from "@/components";
export default {
  name: "Teams",
  components: {
    ProfileCard,
  },
  data() {
    return {
      cardProfile3: {
        cardProfile1: require("@/assets/img/faces/card-profile1-square.jpg"),
        cardProfile2: require("@/assets/img/faces/card-profile6-square.jpg"),
        cardProfile3: require("@/assets/img/faces/card-profile4-square.jpg"),
        cardProfile4: require("@/assets/img/faces/card-profile2-square.jpg"),
      },
      team5: require("@/assets/img/examples/city.jpg"),
    };
  },
  methods: {
    sectionTeam(image) {
      return {
        backgroundImage: `url(${image})`,
      };
    },
  },
};
</script>

<style lang="scss"></style>
