<template>
  <div class="wrapper">
    <div class="section section-blogs-1">
      <!-- Begin Form Pendaftaran -->
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-80 md-small-size-100 mx-auto">
            <h2 class="title">Pendaftaran Anggota</h2>
            <form-card-pendaftaran
              card-plain
              type="horizontal"
              reverse-row
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="cardBlogPost.cardBlogPost2"
            >
              <template slot="cardContent">
                <h6 class="card-category text-danger">Perhatikan</h6>
                <h3 class="card-title">
                  <a href="javascript:void(0)">Persyaratan</a>
                </h3>
                <p class="card-description">
                  Like so many organizations these days, Autodesk is a company in
                  transition. It was until recently a traditional boxed software company
                  selling licenses. Today, it’s moving to a subscription model. Yet its
                  own business model disruption is only part of the story — and…
                </p>
              </template>
              <template slot="cardContent">
                <h3 class="card-title">
                  <a href="javascript:void(0)">Peraturan</a>
                </h3>
                <p class="card-description">
                  Like so many organizations these days, Autodesk is a company in
                  transition. It was until recently a traditional boxed software company
                  selling licenses. Today, it’s moving to a subscription model. Yet its
                  own business model disruption is only part of the story — and…
                </p>
              </template>
              <template slot="cardFormContent">
                <!-- Inputs -->
                <div id="inputs">
                  <div class="md-layout">
                    <div
                      class="md-layout-item md-size-100 md-xsmall-size-100 md-small-size-50 md-medium-size-25"
                    >
                      <md-field>
                        <md-input
                          v-model="formPendaftaran.identity_number"
                          placeholder="No Indentitas"
                        />
                      </md-field>
                      <md-radio v-model="formPendaftaran.identity_type" value="ktp"
                        >KTP</md-radio
                      >
                      <md-radio v-model="formPendaftaran.identity_type" value="ktm"
                        >KTM</md-radio
                      >
                      <md-field>
                        <md-input
                          v-model="formPendaftaran.name"
                          placeholder="Nama Lengkap"
                        />
                      </md-field>
                      <md-field>
                        <md-input v-model="formPendaftaran.phone" placeholder="No HP" />
                      </md-field>
                      <md-field>
                        <md-input
                          type="email"
                          v-model="formPendaftaran.email"
                          placeholder="Email"
                        />
                      </md-field>
                      <md-field>
                        <md-input
                          v-model="formPendaftaran.domicile"
                          placeholder="Domisili"
                        />
                      </md-field>
                      <md-field>
                        <md-input
                          type="password"
                          v-model="formPendaftaran.password"
                          placeholder="Password"
                        />
                      </md-field>
                      <md-field>
                        <md-input
                          type="password"
                          v-model="formPendaftaran.password_confirmation"
                          placeholder="Konfirmasi Password"
                        />
                      </md-field>
                      <md-checkbox v-model="ketentuan">
                        Apakah anda setuju dengan syarat disamping?
                      </md-checkbox>
                      <div class="button-container justify-content-center">
                        <md-button
                          href="javascript:void(0)"
                          class="md-success md-round mt-3"
                          @click="onSubmitDaftar()"
                        >
                          Daftar
                        </md-button>
                      </div>

                      <div
                        :class="
                          response.status == 'success'
                            ? 'alert alert-success'
                            : 'alert alert-danger'
                        "
                        style="margin-top: 20px"
                        v-if="response != ''"
                      >
                        <div class="container">
                          <button
                            type="button"
                            aria-hidden="true"
                            class="close"
                            @click="
                              (event) =>
                                removeNotify(
                                  event,
                                  response.status == 'success'
                                    ? 'alert-success'
                                    : 'alert-danger'
                                )
                            "
                          >
                            <md-icon>clear</md-icon>
                          </button>
                          <div class="alert-icon">
                            <md-icon>check</md-icon>
                          </div>

                          <div v-if="response.status == 'success'">
                            <b> SUCCESS </b> : {{ response.message }}
                          </div>
                          <div v-if="response.status == 'error'">
                            <b> ERROR </b> :
                            <ol>
                              <li v-for="(value, key) in response.errors" :key="key">
                                {{ value.toString()  }}
                              </li>
                            </ol>
                            <span> </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end inputs -->
              </template>
            </form-card-pendaftaran>
          </div>
        </div>
      </div>
      <!-- End Form Pendaftaran -->
    </div>
  </div>
</template>

<script>
import { POST } from "@/services/request_api.module";

import { formCardPendaftaran } from "@/components";
export default {
  name: "Blogs",
  components: {
    formCardPendaftaran,
  },
  data() {
    return {
      formPendaftaran: {
        identity_number: "",
        identity_type: "",
        name: "",
        phone: "",
        email: "",
        domicile: "",
        password: "",
        password_confirmation: "",
      },
      ketentuan: false,
      response: "",

      //  ======================================

      cardBlogPost: {
        cardBlogPost1: require("@/assets/img/examples/card-blog4.jpg"),
        cardBlogPost2: require("@/assets/img/office2.jpg"),
      },
      cardBlogPost2: {
        cardBlogPost1: require("@/assets/img/examples/blog5.jpg"),
        cardBlogPost2: require("@/assets/img/examples/blog7.jpg"),
      },
      cardBlogPost3: {
        cardBlogPost1: require("@/assets/img/examples/blog8.jpg"),
      },
      cardBlogPost4: {
        cardBlogPost1: require("@/assets/img/bg5.jpg"),
        cardBlogPost2: require("@/assets/img/examples/blog6.jpg"),
      },
    };
  },
  methods: {
    async onSubmitDaftar() {
      // daftar
      if (this.ketentuan) {
        const url = "signup";
        const formData = this.formPendaftaran;
        const jenisRequest = "";

        await this.$store
          .dispatch(POST, { url, jenisRequest, formData })
          .then(async (res) => {
            this.response = res;
          });
      } else {
        alert("centang ketentuan");
      }
    },
    removeNotify(e, notifyClass) {
      this.response = "";
      var target = e.target;
      while (target.className.indexOf(notifyClass) === -1) {
        target = target.parentNode;
      }
      return target.parentNode.removeChild(target);
    },
  },
};
</script>

<style lang="scss"></style>
