<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h2 class="title">
              Silahkan cari blog atau artikel yang kamu cari untuk menambah wawasan dan
              skill kamu.
            </h2>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
              <div class="md-autocomplete">
                <md-autocomplete
                  v-model="selectedEmployee"
                  class="search has-black"
                  :md-options="employees"
                  :md-open-on-focus="false"
                >
                  <label>Search...</label>
                </md-autocomplete>
              </div>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto text-center">
              <tabs
                pills-align="center"
                :tab-active="1"
                :tab-name="[
                  'Semua',
                  'Pemograman',
                  'Sistem Operasi',
                  'Jaringan',
                  'Startup',
                ]"
                plain
                color-button="success"
              >
                <!-- here you can add your content for tab-content -->
                <template slot="tab-pane-1">
                  <div class="md-layout">
                    <div
                      class="md-layout-item md-size-25 md-small-size-100"
                      v-for="(cardBlog, index) in cardBlog"
                      :key="index"
                    >
                      <blog-card
                        card-plain
                        :shadow-normal="false"
                        :no-colored-shadow="false"
                        :card-image="cardBlog"
                      >
                        <template slot="cardContent">
                          <h6 class="card-category text-info">ENTERPRISE</h6>
                          <h4 class="card-title">
                            <a href="javasript:void(0)"
                              >Autodesk looks to future of 3D printing with Project
                              Escher</a
                            >
                          </h4>
                          <p class="card-description">
                            Like so many organizations these days, Autodesk is a company
                            in transition. It was until recently a traditional boxed
                            software company selling licenses.
                            <router-link
                              :to="{
                                name: 'blog-detail',
                              }"
                            >
                              Read More
                            </router-link>
                          </p>
                        </template>
                      </blog-card>
                    </div>
                    <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
                      <pagination
                        v-model="infoPagination"
                        class="pagination-secondcolor"
                        with-text
                        :page-count="5"
                      />
                    </div>
                  </div>
                </template>
                <template slot="tab-pane-2" />
                <template slot="tab-pane-3" />
                <template slot="tab-pane-4" />
                <template slot="tab-pane-5" />
              </tabs>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="container">
          <h3 class="title text-center">Artikel Terpopuler</h3>
          <br />
          <div class="md-layout">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <blog-card
                card-plain
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardBlog.cardBlog1"
              >
                <template slot="cardContent">
                  <h6 class="card-category text-info">ENTERPRISE</h6>
                  <h4 class="card-title">
                    <a href="javasript:void(0)"
                      >Autodesk looks to future of 3D printing with Project Escher</a
                    >
                  </h4>
                  <p class="card-description">
                    Like so many organizations these days, Autodesk is a company in
                    transition. It was until recently a traditional boxed software company
                    selling licenses.
                    <a href="javascript:void(0)">Read More</a>
                  </p>
                </template>
              </blog-card>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <blog-card
                card-plain
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardBlog.cardBlog2"
              >
                <template slot="cardContent">
                  <h6 class="card-category text-success">Startups</h6>
                  <h4 class="card-title">
                    <a href="javasript:void(0)"
                      >Lyft launching cross-platform service this week</a
                    >
                  </h4>
                  <p class="card-description">
                    Don't be scared of the truth because we need to restart the human
                    foundation in truth And I love you like Kanye loves Kanye I love Rick
                    Owens’ bed design but the back is...
                    <a href="javascript:void(0)">Read More</a>
                  </p>
                </template>
              </blog-card>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <blog-card
                card-plain
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardBlog.cardBlog3"
              >
                <template slot="cardContent">
                  <h6 class="card-category text-danger">
                    <md-icon class="text-danger"> trending_up </md-icon> Fashion
                  </h6>
                  <h4 class="card-title">
                    <a href="javasript:void(0)"
                      >6 insights into the French Fashion landscape</a
                    >
                  </h4>
                  <p class="card-description">
                    Don't be scared of the truth because we need to restart the human
                    foundation in truth And I love you like Kanye loves Kanye I love Rick
                    Owens’ bed design but the back is...
                    <a href="javascript:void(0)">Read More</a>
                  </p>
                </template>
              </blog-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tabs, FullBgCard, BlogCard, ProfileCard, Pagination } from "@/components";
import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    Tabs,
    FullBgCard,
    BlogCard,
    ProfileCard,
    Pagination,
  },
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      subscribe: null,
      image: require("@/assets/img/examples/bg-blog-posts.jpg"),
      cardProject: {
        cardProject1: require("@/assets/img/examples/office2.jpg"),
        cardProject2: require("@/assets/img/examples/blog8.jpg"),
        cardProject3: require("@/assets/img/examples/card-project6.jpg"),
      },
      cardProfile3: {
        cardProfile1: require("@/assets/img/faces/card-profile1-square.jpg"),
        cardProfile2: require("@/assets/img/faces/card-profile4-square.jpg"),
      },
      cardBlog: {
        cardBlog1: require("@/assets/img/bg5.jpg"),
        cardBlog2: require("@/assets/img/examples/blog5.jpg"),
        cardBlog3: require("@/assets/img/examples/blog6.jpg"),
        cardBlog4: require("@/assets/img/examples/blog6.jpg"),
      },
      infoPagination: 3,
      selectedEmployee: "",
      employees: [
        "Jim Halpert",
        "Dwight Schrute",
        "Michael Scott",
        "Pam Beesly",
        "Angela Martin",
        "Kelly Kapoor",
        "Ryan Howard",
        "Kevin Malone",
      ],
    };
  },
};
</script>

<style lang="scss"></style>
