<template>
  <div class="wrapper">
    <div class="section page-header header-filter" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-33 md-small-size-66 md-xsmall-size-100 md-medium-size-40 mx-auto"
          >
            <login-card header-color="secondcolorpetisigradient">
              <h4 slot="title" class="card-title">Login</h4>
              <!-- <md-button
                slot="buttons"
                href="javascript:void(0)"
                class="md-just-icon md-simple md-white"
              >
                <i class="fab fa-facebook-square" />
              </md-button>
              <md-button
                slot="buttons"
                href="javascript:void(0)"
                class="md-just-icon md-simple md-white"
              >
                <i class="fab fa-twitter" />
              </md-button>
              <md-button
                slot="buttons"
                href="javascript:void(0)"
                class="md-just-icon md-simple md-white"
              >
                <i class="fab fa-google-plus-g" />
              </md-button> -->
              <p slot="description" class="description">
                Silahkan isi kolom dibawah untuk login
              </p>

              <md-field slot="inputs" class="md-form-group">
                <md-icon>email</md-icon>
                <label>Email...</label>
                <md-input v-model="email" type="email" />
              </md-field>
              <md-field slot="inputs" class="md-form-group">
                <md-icon>lock_outline</md-icon>
                <label>Password...</label>
                <md-input v-model="password" />
              </md-field>
              <md-button
                slot="footer"
                class="md-simple md-secondcolor md-lg"
                @click="onSubmit()"
              >
                Login
              </md-button>
            </login-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { POST } from "@/services/request_api.module";

import { LoginCard } from "@/components";
import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    LoginCard,
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "login-page",
  data() {
    return {
      image: require("@/assets/img/petisi/night.png"),
      firstname: null,
      email: null,
      password: null,
    };
  },
  methods: {
    async onSubmit() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/sanctum/csrf-cookie`)
        .then(async (response) => {
          // Login...

          const url = "signin";
          const formData = {
            email: this.email,
            password: this.password,
          };
          const jenisRequest = "";

          await this.$store
            .dispatch(POST, { url, jenisRequest, formData })
            .then(async (res) => {
              console.log(res);
            });
        });
    },
  },
};
</script>

<style lang="css"></style>
