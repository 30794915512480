<template>
  <div class="wrapper">
    <!-- Features 3 -->
    <div class="section section-features-3">
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-100"
            style="display: flex; align-items: center"
          >
            <div class="petisifotobersama-container">
              <img :src="image" />
            </div>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <info-areas info-horizontal icon-color="primary" icon="bookmark">
              <h4 slot="title" class="info-title">Tentang Kami</h4>
              <div slot="content" class="description tentangkami">
                <p>
                  Komunitas Pegiat Teknologi Informas dan Siber merupakan wadah persatuan
                  para pegiat Teknologi, Informasi dan Keamanan Siber yang menurut
                  fungsinya bergerak atau berhubungan erat dengan bidang Teknologi
                  Informasi dan Keamanan Siber
                </p>
              </div>
            </info-areas>
            <info-areas info-horizontal icon-color="primary" icon="flag">
              <h4 slot="title" class="info-title">Visi</h4>
              <div slot="content" class="description tentangkami">
                <p>
                  Menjadi organisasi yang memiliki anggota profesional, berintegritas, dan
                  berprestasi, serta dapat berkontribusi bagi masyarakat dalam bidang
                  teknologi informasi dan keamanan siber.
                </p>
              </div>
            </info-areas>
            <info-areas info-horizontal icon-color="primary" icon="extension">
              <h4 slot="title" class="info-title">Misi</h4>
              <div slot="content" class="description tentangkami">
                <p>
                  Menjadikan organisasi memiliki budaya sharing knowledge antara para
                  anggota. Menjadikan organisasi sebagai wadah untuk meningkatkan
                  keahlian, mutu, profesionalitas, integritas, etos kerja, dan berdaya
                  saing tinggi. Membina anggota yang ingin menjadi technopreneur,
                  membangun startup atau bisnis dibidang teknologi informasi dan keamanan
                  siber. Mengoptimalkan peran organisasi dalam bersinergi terhadap
                  Pemerintahan dan Mitra. Ikut berkontribusi dalam memajukan daerah
                  khususnya Kalimantan Selatan di bidang Teknologi Informasi dan Keamanan
                  Siber.
                </p>
              </div>
            </info-areas>
          </div>
        </div>
      </div>
    </div>
    <!-- end Features 3 -->
  </div>
</template>

<script>
import { InfoAreas } from "@/components";

export default {
  name: "Features",
  components: {
    InfoAreas,
  },
  data() {
    return {
      image: require("@/assets/img/petisi/fotobersamapetisi.jpeg"),
    };
  },
  computed: {},
};
</script>

<style lang="scss">
.description {
  .tentangkami {
    font-weight: bold;
  }
}
</style>
