<template>
  <!-- <div class="wrapper">
    <div id="footer-areas"> -->
  <!-- Big Footer -->
  <footer
    class="footer footer-black footer-big"
    :class="{
      [`footer-${type}`]: type,
      [`footer-${size}`]: size,
    }"
    :data-background-color="backgroundColor"
  >
    <template v-if="$route.name == 'login'">
      <div class="container">
        <nav>
          <ul>
            <li>
              <router-link to=""> Blog </router-link>
            </li>
            <li>
              <router-link to=""> Event </router-link>
            </li>
            <li>
              <router-link to=""> Pendaftaran </router-link>
            </li>
          </ul>
        </nav>
        <div class="copyright">
          Copyright © {{ year }} By Community PETISI
        </div>
      </div>
    </template>
    <template v-else>
      <div class="container">
        <div class="content">
          <div class="md-layout">
            <div class="md-layout-item md-medium-size-33 md-small-size-100">
              <h5>Tentang Kami</h5>
              <p>
                Komunitas Pegiat Teknologi Informas dan Siber merupakan wadah persatuan
                para pegiat Teknologi, Informasi dan Keamanan Siber yang menurut fungsinya
                bergerak atau berhubungan erat dengan bidang Teknologi Informasi dan
                Keamanan Siber
              </p>
            </div>

            <div class="md-layout-item md-medium-size-33 md-small-size-100">
              <h5>Informasi</h5>
              <div class="social-feed">
                <router-link to="">
                  <div class="feed-line"><p>Syarat dan Ketentuan</p></div>
                </router-link>
                <router-link to="">
                  <div class="feed-line"><p>Cara Daftar Menjadi Anggota</p></div>
                </router-link>
                <router-link to="">
                  <div class="feed-line"><p>FAQ</p></div>
                </router-link>
                <router-link to="">
                  <div class="feed-line"><p>Tentang Kami</p></div>
                </router-link>
              </div>
            </div>

            <div class="md-layout-item md-medium-size-33 md-small-size-100">
              <h5>Sosial media</h5>
              <div class="social-feed">
                <a href="http://">
                  <div class="feed-line">
                    <i class="fab fa-twitter" />
                    <p>@petisi</p>
                  </div>
                </a>
                <a href="http://">
                  <div class="feed-line">
                    <i class="fab fa-instagram" />
                    <p>@petisi</p>
                  </div>
                </a>
                <a href="http://">
                  <div class="feed-line">
                    <i class="fab fa-facebook-square" />
                    <p>petisi</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="flex flex-direction-row space-between">
          <nav>
            <ul>
              <li>
                <router-link to=""> Blog </router-link>
              </li>
              <li>
                <router-link to=""> Event </router-link>
              </li>
              <li>
                <router-link to=""> Pendaftaran </router-link>
              </li>
            </ul>
          </nav>
          <div class="copyright float-right">Copyright © {{ year }} By Community PETISI</div>
        </div>
      </div>
    </template>
  </footer>
  <!-- end Big Footer -->
  <!-- </div>
  </div> -->
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
    size: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
