<template>
  <div class="wrapper">
    <!-- Headers 3 -->
    <div class="pt-0 pb-0">
      <el-carousel
        indicator-position="none"
        class="no-border no-height"
        trigger="click"
        :interval="5000"
      >
        <el-carousel-item>
          <div class="page-header header-filter" :style="header('3')">
            <div class="container">
              <div class="md-layout">
                <div class="md-layout-item md-size-66 md-small-size-100 text-left">
                  <h1 class="title">
                    PETISI (Komunitas Pegiat Teknologi Informas dan Siber)
                  </h1>
                  <h4>
                    Komunitas Pegiat Teknologi Informas dan Siber merupakan wadah
                    persatuan para pegiat Teknologi, Informasi dan Keamanan Siber yang
                    menurut fungsinya bergerak atau berhubungan erat dengan bidang
                    Teknologi Informasi dan Keamanan Siber
                  </h4>
                  <br />
                  <div class="buttons">
                    <md-button
                      href="https://t.me/+SirDozcLF2AxZDNl"
                      class="md-simple md-white md-just-icon md-lg"
                    >
                      <i class="fab fa-telegram"></i>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="page-header header-filter" :style="header('4')">
            <div class="container">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >
                  <h1 class="title">Diskusi</h1>
                  <h4>
                    Kami memiliki forum diskusi seputar IT di group Telegram. Didalamnya
                    terdapat orang-orang yang berpengalaman dan terjun menjadi praktisi
                    dalam pekerjaan IT.
                  </h4>
                  <br />
                  <h6>Connect with us on:</h6>
                  <div class="buttons">
                    <md-button
                      href="https://t.me/+SirDozcLF2AxZDNl"
                      target="_blank"
                      class="md-simple md-white md-lg md-just-icon ml-auto mr-auto"
                    >
                      <i class="fab fa-telegram"></i>
                    </md-button>
                    <!-- <md-button
                      href="javascript:void(0)"
                      class="md-facebook md-simple md-white md-lg md-just-icon"
                    >
                      <i class="fab fa-facebook-square" />
                    </md-button>
                    <md-button
                      href="javascript:void(0)"
                      class="md-google md-simple md-white md-lg md-just-icon"
                    >
                      <i class="fab fa-google-plus-g" />
                    </md-button>
                    <md-button
                      href="javascript:void(0)"
                      class="md-instagram md-simple md-white md-lg md-just-icon mr-auto"
                    >
                      <i class="fab fa-instagram" />
                    </md-button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="page-header header-filter" :style="header('5')">
            <div class="container">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 ml-auto text-right"
                >
                  <h1 class="title">
                    Komunitas Berlembaga Hukum Legal
                  </h1>
                  <!-- <h4>
                    They’re baaaack! I just spotted the greatest beverages on Earth in my
                    local Rite Aid while shopping for New Year’s Eve refreshments.
                  </h4> -->
                  <br />
                  <div class="buttons">
                    <!-- <md-button
                      href="javascript:void(0)"
                      class="md-white md-simple md-lg ml-auto"
                    >
                      <md-icon>share</md-icon> Share Offer
                    </md-button> -->
                    <md-button href="javascript:void(0)" class="md-danger md-lg ml-auto">
                      <md-icon>description</md-icon> Lihat Dokumen
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- end Headers 3 -->
  </div>
</template>

<script>
export default {
  name: "Headers",
  data() {
    return {
      companyName: null,
      companyEmail: null,
      companyPassword: null,
      toggledClass: false,
      headerImages: {
        Image1: require("@/assets/img/bg12.jpg"),
        Image2: require("@/assets/img/examples/office2.jpg"),
        Image3: require("@/assets/img/dg1.jpg"),
        Image4: require("@/assets/img/dg2.jpg"),
        Image5: require("@/assets/img/dg3.jpg"),
      },
    };
  },
  methods: {
    header(nr) {
      return {
        backgroundImage: `url(${this.headerImages[`Image${nr}`]})`,
      };
    },
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
  },
};
</script>

<style lang="scss">
.buttons {
  display: flex;
  align-items: center;
}

#headers {
  .el-carousel__container {
    height: 100vh !important;
  }
  .el-carousel {
    border-radius: 0 !important;
  }
}

.text-right {
  text-align: right;
}

.border-radius-0 {
  border-radius: 0;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}

@media all and (max-width: 960px) {
  .md-card-form-horizontal .md-layout-item {
    margin-top: 1.5rem;
  }
}
</style>
