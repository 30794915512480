class auth {

    setStorage(res, rememberMe) {
       
    }

    removeStorage() {
       
    }

}



export default auth = new auth();
